import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import "./AdminCashback.css";

const AdminCashback = () => {
  const [cashback, setCashback] = useState({
    cashback: "",
    status: "",
  });

  function handleChange(e) {
    setCashback({ ...cashback, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (cashback?.status === "") {
      return message.error("Please select status");
    }
    try {
      const res = await axios.post("/api/cashback/update", cashback, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        getCashback();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getCashback() {
    try {
      const res = await axios.get("/api/cashback/getcashback");
      if (res.data.success) {
        setCashback(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCashback();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users">
        <div className="page-title">
          <h3 className="m-0">Cashback</h3>
        </div>
        <hr />
        <div className="noti-container">
          <div className="form-fields">
            <input
              placeholder="Enter Cashback (only number)"
              type="text"
              name="cashback"
              className="form-control mb-3"
              onChange={handleChange}
              value={cashback?.cashback}
            />
            <label className="text-dark">Status</label>
            <select
              name="status"
              className="form-select mb-2"
              onChange={handleChange}
              value={cashback?.status}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <button onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminCashback;
