import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import StickyFooter from "./StickyFooter";
import "../Footer/Footer.css";

const Footer = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <StickyFooter />
        <div className="wa-icon">
          <WhatsAppIcon
            className="icon"
            onClick={() => window.open("https://wa.me/918131958835")}
          />
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">
              Ivy<span>Store</span>
            </div>
            <span>+91 8131958835</span>
            <br />
            <span>tpachhakchhuak11@gmail.com</span>
            <br />
            <span>Mizoram, India</span>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/Games">Games</Link>
              </li>
              {!user ? (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link to="/user-dashboard">Dashboard</Link>
                </li>
              )}
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Connect here</h6>
            <div className="social-media-links">
              <Link
                target="_blank"
                to="https://www.instagram.com/tpa_d?igsh=MTFheDQwYno1NDRzdw%3D%3D&utm_source=qr"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to="https://www.facebook.com/tpad.d.chhakchhuak?mibextid=LQQJ4d"
              >
                <FacebookIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/918131958835">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:tpachhakchhuak11@gmail.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Contact Developer</h6>
            <ul>
              <li>
                <Link
                  style={{ color: "var(--t)", fontWeight: "500" }}
                  to="https://aashirdigital.com/"
                  target="_blank"
                >
                  Click Here
                </Link>
              </li>
            </ul>
          </div>

          <hr />
          <span className="text-start copyright">
            <small>
              All Rights Reserved © 2024 | IVY STORE |{" "}
              <br className="d-block d-md-none d-lg-none" />
              Website designed & developed by{" "}
              <Link
                target="_blank"
                style={{ color: "var(--t)", fontWeight: "500" }}
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
