import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import "./StickyFooter.css";

const StickyFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="sticky-footer d-flex d-lg-none">
      <div className="item" onClick={() => navigate("/")}>
        <HomeOutlinedIcon
          className={`icon ${location.pathname === "/" && "active"}`}
        />
        <span className={`${location.pathname === "/" && "active"}`}>Home</span>
      </div>
      <div className="item" onClick={() => navigate("/wallet")}>
        <AccountBalanceWalletOutlinedIcon
          className={`icon ${location.pathname === "/wallet" && "active"}`}
        />
        <span className={`${location.pathname === "/wallet" && "active"}`}>
          Wallet
        </span>
      </div>
      <div className="item" onClick={() => navigate("/orders")}>
        <FormatListBulletedOutlinedIcon
          className={`icon ${location.pathname === "/orders" && "active"}`}
        />
        <span className={`${location.pathname === "/orders" && "active"}`}>
          Orders
        </span>
      </div>
      <div className="item" onClick={() => navigate("/contact")}>
        <SupportAgentOutlinedIcon
          className={`icon ${location.pathname === "/contact" && "active"}`}
        />
        <span className={`${location.pathname === "/contact" && "active"}`}>
          Support
        </span>
      </div>
      <div className="item" onClick={() => navigate("/my-account")}>
        <AccountCircleOutlinedIcon
          className={`icon ${location.pathname === "/my-account" && "active"}`}
        />
        <span className={`${location.pathname === "/my-account" && "active"}`}>
          Account
        </span>
      </div>
    </div>
  );
};

export default StickyFooter;
